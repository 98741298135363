<template>
    <form
        novalidate
        @submit.prevent=""
        class="edit-passport"
    >
        <PassportInformation/>
        <div class="edit-passport__actions">
            <Button
                back
                @click.native="back"
            >
                Назад
            </Button>
            <Button type="submit">
                Сохранить
            </Button>
        </div>
    </form>
</template>

<script>
import './edit-passport.scss'

import PassportInformation from '@index/components/application/passport-information/PassportInformation'
import Button from '@index/components/gui/button/Button'

export default {
    name: 'EditPassport',
    methods: {
        back() {
            this.$router.push({ name: 'Profile' })
        }
    },
    components: {
        PassportInformation,
        Button
    }
}
</script>